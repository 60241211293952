* {
  box-sizing: border-box;
}

html {
  background: #dc0f1e;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}
